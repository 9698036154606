body {
  margin: 0 !important;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.truncateText1 {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 11px;
}
.truncateText2 {
  max-width: 240px;
  overflow-wrap: break-word;
  display: inline-block;
  font-size: 11px;
    text-decoration: underline;
}
.tinyTxt{
  font-size: 10px !important;
  height: 18px !important;
  margin-right: 5px;
}
.goodMachine  > td:first-child {
   border-left: 5px solid #37a93b79 !important;
   border-radius: 5px;
 }
 .warningMachine > td:first-child {
  border-left: 5px solid #ed9a0979 !important;
  border-radius: 5px;
}
.warningMachine span {
  color: #996202 !important;
}
.disabledMachine{
  background: #eee;
}
.disabledMachine>td:first-child {
  border-left: 5px solid rgb(48, 48, 48) !important;
  border-radius: 5px 0 0 5px;
}
.repairingMachine {
  background: #b1b0b0;
}
.repairingMachine>td:first-child {
  border-left: 5px solid rgb(139, 139, 139) !important;
  border-radius: 5px 0 0 5px;
}
.snoozedMachine {
  background: repeating-linear-gradient(45deg,
      transparent,
      transparent 10px,
      #ffbb0010 10px,
      #ffbb0010 20px)
}
.snoozedMachine > td:first-child {
  border-left: 5px solid #ffbb00 !important;
  border-radius: 5px 0 0 5px;
}
.errorMachine {
  background: repeating-linear-gradient(45deg,
      transparent,
      transparent 10px,
      #fbf1f160 10px,
      #fbf1f160 20px)
}
.errorMachine > td:first-child {
    border-left: 5px solid #E00 !important;
    border-radius: 5px 0 0 5px;
}
.tightTable td{
  padding:2px 3px;
  text-align: center;
}
.tightTable td,.tightTable th{
  border-left: 1px solid rgba(224, 224, 224, 1);
}
.machineBillingTable{
  min-width: 90px;
  text-align : center;
  line-height :14px;
}
.machineBillingTable span{
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}


.fixedHeadCol {
  position: sticky;
  left: -1px;
  top: auto;
  min-width: 200px;
  max-width: 200px;
  z-index: 2;
  background: #fff;
  border-right: 1px solid #ccc !important;
}

.fixedHeadRow {
  position: sticky;
  top:-1px;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid #ccc !important;
}
.fixedHeadCol.fixedHeadRow{
  left: -1px;
  top: -1px;
  z-index: 3;
}
.fixedTable{

}
table.fixedTable,
.fixedTable th,
.fixedTable td {
  border: 1px solid #ccc;
}


.customSwitch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.customSwitch input {
  opacity: 0;
  width: 30px;
  height: 17px;
  margin: 0;
}

.customSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.customSlider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.customSwitch input:checked+.customSlider {
  background-color: #04AA6D;
}

.customSwitch input:focus+.customSlider {
  box-shadow: 0 0 1px #04AA6D;
}

.customSwitch input:checked+.customSlider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded customSliders */
.customSlider.round {
  border-radius: 34px;
}

.customSlider.round:before {
  border-radius: 50%;
}